<script setup>
  import { computed, defineEmits, defineProps, ref, watch } from 'vue'
  import { useVuelidate } from "@vuelidate/core"

	import { toDateTime } from '@/scripts/datetime'
  import form from '@/scripts/form'

  const changed = ref(false)
  const loading = ref(false)

  const emit = defineEmits(['update:modelValue'])
  const props = defineProps(['id', 'modelValue'])

  const modelValue = computed(() => props.modelValue)

  const rules = {
    from_exness_id: {},
    partner_account: {},
    client_uid: {},
    country: {},
    currency: {},
    reg_date: {},
    volume_lots: {},
    volume_mln_usd: {},
    trade_fn: {},
    reward: {},
    reward_usd: {},
    comment: {},
    client_account: {},
    client_account_type: {},
  }

  const model = form.initial(rules, modelValue.value)

  const v$ = useVuelidate(rules, model)

  watch(model, (newValue) => {
    changed.value = true

    emit('update:modelValue', newValue)
  })

  loading.value = false
</script>

<template>
  <label class="block text-900 font-medium mb-2" for="uid">Exness ID</label>
  <InputText v-model="v$.from_exness_id.$model" id="uid" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="phone">Partner Account</label>
  <InputText v-model="v$.partner_account.$model" id="phone" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="email">Client UID</label>
  <InputText v-model="v$.client_uid.$model" id="email" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="first_name">Country</label>
  <InputText v-model="v$.country.$model" id="first_name" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="last_name">Currency</label>
  <InputText v-model="v$.currency.$model" id="last_name" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="date_of_birth">Register Date</label>
  <InputText v-model="v$.reg_date.$model" id="date_of_birth" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="address">Volume Lots</label>
  <InputText v-model="v$.volume_lots.$model" id="address" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="address">Volume MLN USD</label>
  <InputText v-model="v$.volume_mln_usd.$model" id="address" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="address">Trade Function</label>
  <InputText v-model="v$.trade_fn.$model" id="address" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="address">Reward</label>
  <InputText v-model="v$.reward.$model" id="address" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="address">Reward USD</label>
  <InputText v-model="v$.reward_usd.$model" id="address" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="address">Comment</label>
  <InputText v-model="v$.comment.$model" id="address" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="address">Client Account</label>
  <InputText v-model="v$.client_account.$model" id="address" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="address">Client Account Type</label>
  <InputText v-model="v$.client_account_type.$model" id="address" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="created_at">Created At</label>
  <InputText :value="toDateTime(modelValue.created_at)" id="created_at" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="updated_at">Updated At</label>
  <InputText :value="toDateTime(modelValue.updated_at)" id="updated_at" type="text" class="w-full mb-3" readonly />
</template>
